import { h } from 'preact';
import { Router } from 'preact-router';

import Home from '../routes/home'
import Client from '../routes/client'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from 'preact/hooks';
import Context from '../context';


const App = () => {
	
	const [remoteMarker, setRemoteMarker] = useState(null)
	const [state, setState] = useState({})
	const [flipH, setFlipH] = useState(false)
	const [flipV, setFlipV] = useState(false)

	useEffect(() => {
		const urlSearchParams = new URLSearchParams(window.location.search)
		const params = Object.fromEntries(urlSearchParams.entries())

		if (params.fliphori) {
			setFlipH(true)
		}

		if (params.flipvert) {
			setFlipV(true)
		}
	}, [])

	return <div id="app" className={`${flipH ? 'flip-h' : ''} ${flipV ? 'flip-v' : ''}`}>
			<Context.Provider value={{state, setState}}>
				<Router>
					<Home path="/" remoteMarker={remoteMarker} setRemoteMarker={setRemoteMarker} />
					<Client path="/call/:callId" remoteMarker={remoteMarker} setRemoteMarker={setRemoteMarker} />
				</Router>
			
				<ToastContainer />
			</Context.Provider>
		</div>
	}
export default App;
